<template>
  <div class="row mt-2 pr-0">
    <table class="table table-bordered">
      <tbody>
      <tr>
        <td class="fw-bold">Customer name</td>
        <td>{{ invoice.customerName }}</td>
      </tr>
      <tr>
        <td class="fw-bold">Status</td>
        <td>{{ invoice.status }}</td>
      </tr>
      <tr>
        <td class="fw-bold">Created date</td>
        <td>{{ invoice.createdDate }}</td>
      </tr>
      <tr>
        <td class="fw-bold">Invoice Number</td>
        <td>{{ invoice.invoiceNumber }}</td>
      </tr>
      <tr>
        <td class="fw-bold">Contact Number</td>
        <td>
          <a :href="`tel:${invoice.contactNumber}`">{{ invoice.contactNumber }}</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Header',
  data() {
    return {
      invoice: [],
    }
  },
  async beforeMount() {
    await this.fetchInvoice();
  },
  methods: {
    async fetchInvoice() {
      try {
        const response = await axios.get(synergy+'jobcards/jobCardInvoices/7');
        if (response.data.length > 0) {
          this.invoice = response.data[0];
        }
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-icon {
  font-size: 20px;
  color: #0b66ff;
}
</style>