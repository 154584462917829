<template>
  <div class="follow-up-page">
    <h1 class="title">Follow Up Requests</h1>
    <!-- Loader Section -->
    <div v-if="loading" class="loader-wrapper">
      <div class="loader"></div>
      <div class="loading-text">Loading data, please wait...</div>
    </div>
    <div v-else>
      <div v-if="followUpData.length === 0" class="no-data">No follow-ups available.</div>
      <table v-else class="table table-hover table-sm mt-2">
        <thead>
        <tr>
          <th>#</th>
          <th>Customer</th>
          <th>Status</th>
          <th>Date</th>
          <th>Contact</th>
          <th>Invoice Number</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in followUpData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.customerName }}</td>
          <td>{{ item.status }}</td>
          <td>{{ formatDate(item.createdDate) }}</td>
          <td>{{ item.contactNumber }}</td>
          <td>{{ item.invoiceNumber }}</td>
          <td>
            <a :href="`http://192.168.1.44:8081/?id=${item.invoiceNumber}`" target="_blank" class="btn btn-link">Get KYC</a>
              <a :href="`https://realtime.abc.co.zm:8443/PrintApi/Quotes/burntofi_chrilan/invoice/${item.invoiceNumber}?PC=Online&reprint=true`" target="_blank" class="btn btn-link">Invoice</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FollowUp',
  data() {
    return {
      loading: false,
      followUpData: [],
    };
  },
  async created() {
    this.loading = true;
    await this.fetchFollowUpData();
  },
  methods: {
    async fetchFollowUpData() {
      try {
        const followUpApiUrl = window.synergy2 + 'jobcards/pendingInvoices';
        const response = await axios.get(followUpApiUrl);

        console.log(response);

        this.followUpData = response.data;

      } catch (error) {
        errorHandler.tomcatError(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    getKycLink(invoiceNumber) {
      return `${window.synergy2}kyc/${invoiceNumber}`;
    },
    getInvoiceLink(invoiceNumber) {
      return `${window.synergy2}invoice/${invoiceNumber}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.follow-up-page {
  margin: 20px;
}

.title {
  margin-bottom: 30px;
  color: grey;
}

/* Loader Styles */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 10px;
  font-size: 18px;
  color: #555;
}

.no-data {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.table {
  width: 100%;
  margin-top: 20px;
}

.table th, .table td {
  text-align: left;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.btn-link:hover {
  text-decoration: underline;
}
</style>
