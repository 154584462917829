<template>
  <div class="container-fluid">
    <h1 class="title">Available Stock</h1>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else class="table-wrapper">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th>Stock ID</th>
          <th>Stock Name</th>
          <th>Available Quantity</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(stock, index) in stockData" :key="index">
          <td>{{ stock.stockId }}</td>
          <td>{{ stock.stockName }}</td>
          <td>{{ stock.availableQuantity }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailableStock',
  data() {
    return {
      stockData: [],
      loading: true,
    };
  },
  created() {
    this.fetchStockData();
  },
  methods: {
    async fetchStockData() {
      try {
        const stockId = this.$route.params.stockId; // Get the stockId from the route params
        const apiUrl = `http://gomah:8080/SynergyApi/api/stock-controller/showRequiredStock`; // Replace with actual API endpoint
        const response = await axios.get(apiUrl, {
          params: { jobCardNo }, // Pass stockId as a query parameter
        });
        this.stockData = response.data;
      } catch (error) {
        console.error('Error fetching stock data:', error);
        this.$router.push('/error'); // Redirect to an error page if needed
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
}

.title {
  margin: 20px;
  text-align: center;
}

.loading {
  text-align: center;
  font-size: 18px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
}
</style>
