import {Storage as Store} from '@capacitor/storage';

export const CurrentUser = 'current_user';
export const AllUsers = 'all_users';
export const DEBTORS = 'debtors';
export const Ticket_Statuses = 'ticket_statuses';
export const Ticket_Types = 'ticket_types';

export const Storage = {
    setCurrentUser: async (payload) => {
        await Store.set({
            key: CurrentUser, value: JSON.stringify(payload)
        })
    },
    getCurrentUser: async () => {
        const value = await Store.get({
            key: CurrentUser
        })
        return JSON.parse(value.value);
    },

    setUsers: async (payload) => {
        await Store.set({
            key: AllUsers, value: JSON.stringify(payload)
        })
    },

    getAllUsers: async () => {
        const value = await Store.get({
            key: AllUsers
        })
        return JSON.parse(value.value);
    },

    setDebtors: async (payload) => {
        await Store.set({
            key: DEBTORS, value: JSON.stringify(payload)
        })
    },

    getAllDebtors: async () => {
        const value = await Store.get({
            key: DEBTORS
        })
        return JSON.parse(value.value);
    },

    setStatuses: async (payload) => {
        await Store.set({
            key: Ticket_Statuses, value: JSON.stringify(payload)
        })
    },

    getAllTicketStatuses: async () => {
        const value = await Store.get({
            key: Ticket_Statuses
        })
        return JSON.parse(value.value);
    },

    setTicketTypes: async (payload) => {
        await Store.set({
            key: Ticket_Types,
            value: JSON.stringify(payload)
        })
    },

    getAllTicketTypes: async () => {
        const value = await Store.get({
            key: Ticket_Types
        })
        return JSON.parse(value.value);
    },
}
