import {Storage} from "@/services/Storage";

export default {
    name: 'homeScreen',
    state: {
        currentTab: 1
    },
    getters: {
        getCurrentTab: (state) => state.currentTab,
    },
    mutations: {
        setCurrentTab: (state, value = 1) => state.currentTab = value,
    },
    actions: {
        getTicketsByTab: async (context, tabNumber) => {
            let user = await Storage.getCurrentUser();
            let userID = user.id;
            if (tabNumber) {
                await context.commit('setCurrentTab', tabNumber);
            }
            let tab = context.getters.getCurrentTab;
            let data = [];
            switch (tab) {
                case 1:
                    data = await context.dispatch('getTicketsByUserID', userID)
                    break;
                case 2 :
                    data = await context.dispatch('getAllOpenTickets')
                    break;
                case 3 :
                    data = await context.dispatch('getAllTicketsDueToday')
                    break;
                case 4 :
                    data = await context.dispatch('getAllTicketsClosedToday')
                    break;
                case 5:
                    data = await context.dispatch('getUserTicketsPastDue', userID)
                    break;
                case 7 :
                    data = await context.dispatch('getAllTicketscreatedToday')
                    break;
                default:
                    data = await context.dispatch('getTicketsByUserID', userID)
            }
            context.commit('setTickets', data);
        },

        printHomeScreenTicket: (context) => {
            let user = context.getters.getCurrentUser.id;
            let currentTab = context.getters.getCurrentTab;
            switch (currentTab) {
                case 1:
                    return context.dispatch('printUserTickets', user);
                case 2:
                    return context.dispatch('printAllOpenTickets');
                case 3:
                    return context.dispatch('printTicketsDueToday');
                case 4 :
                    return context.dispatch('printTicketsClosedToday');
                case 5 :
                    return context.dispatch('printPastDueByUser', user);
                default:
                    return context.dispatch('printAllPastDue');
            }
        },
    }

}
