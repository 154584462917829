import Vue from 'vue'
import Vuex from 'vuex'
import user_module from "@/store/modules/user_module";
import ticket_module from "@/store/modules/ticket_module";
import customer_module from "@/store/modules/customer_module";
import ticket_notes_module from "@/store/modules/ticket_notes_module";
import ticket_tasks_module from "@/store/modules/ticket_tasks_module";
import ticket_document_module from "@/store/modules/ticket_document_module";
import print_module from "@/store/modules/print_module";
import emailModule from "@/store/modules/email-module";
import chatModule from "@/store/modules/chat-module";
import expenseModule from "@/store/modules/expense-module";
import axios from "axios";
import router from "@/router";
import home_screen_module from "@/store/modules/home_screen_module";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        isUpdateAvailable: false,
        isSynergyLive : false,
    },
    getters: {
        getLoadingState: (state) => state.loading,
        getSynergyAvailability: (state) => state.isSynergyLive,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
},
    mutations: {
        setLoadingState: (state, payload) => state.loading = payload,
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
    },
    actions: {
        getDashboardData: (context) => {
            return axios.all([
                context.dispatch('getCurrentUserTicketCount'),
                context.dispatch('getOpenTicketCount'),
                context.dispatch('getTicketsByTab', 1),
                context.dispatch('getAllTicketsGroupedByCustomer'),
            ]);
        },
        getPreLoginData: (context, isRefresh = false) => {
            return axios.all([
                context.dispatch('getAllUsers'),
                context.dispatch('getAllDebtors'),
                context.dispatch('getAllTicketStatuses'),
                context.dispatch('getAllTicketTypes'),
                context.dispatch('getPaymentOptions'),
            ]);
        },
    },
    modules: {
        user_module,
        ticket_module,
        customer_module,
        ticket_notes_module,
        ticket_tasks_module,
        ticket_document_module,
        home_screen_module,
        print_module,
        emailModule,
        chatModule,expenseModule
    }
})
