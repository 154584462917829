<template>
  <div>
    <NavigationDrawer/>
    <div class="area">
      <div class="offline-banner" v-if="offline">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation"/>
        Internet disconnected
      </div>

      <a class="update-banner" @click="updateApp" v-if="isUpdateAvailable">
        <font-awesome-icon icon="circle-exclamation"/>
        Update Available (Click to update)
      </a>
      <transition-page class="p-3 transition-page">
        <router-view/>
      </transition-page>
    </div>
  </div>
</template>

<script>
import TransitionPage from "@/components/layout/animations/TransitionPage";
import NavigationDrawer from "@/components/layout/navigation/NavigationDrawer";

export default {
  name: "Layout",
  computed : {
    isUpdateAvailable(){
      return this.$store.getters.getUpdateAvailability;
    }
  },
  components: {
    NavigationDrawer,
    TransitionPage
  },
  beforeMount() {
    this.checkOnline();
  },
  methods: {
    checkOnline() {
      return this.$nextTick(() => {
        window.setInterval(() => {
          this.offline = !navigator.onLine;
        }, 200);
      })
    },
    toggle() {
      this.$refs.drawer.toggle()
    },
    openDrawer() {
      this.$refs.drawer.toggle();
    },
    closeDrawer() {
      this.$refs.drawer.toggle(false);
    },
    updateApp(){
      this.$store.commit(`setUpdateAvailability` , false)
      this.$utils.showLoading()
      return window.location.reload(true);
    }
  },
  data() {
    return {
      offline: true,
    }
  }
}
</script>

<style lang="scss" scoped>

.transition-page{
  min-height: 100vh !important;
}
.area {
  float: left;
  background: hsl(0, 0%, 100%);
  width: 100%;
  height: 100%;
  padding-left: 3.375rem;
  font-family: "Poppins", sans-serif;
}

.area > h1 {
  padding-bottom: 1.5rem;
}

.area > p {
  font-size: 1rem;
  padding-bottom: 1rem;
}

.offline-banner {
  position: sticky;
  top: 0;
  left: 0;
  padding: 5px;
  display: block;
  text-align: center;
  width: 100%;
  background: rgb(220, 53, 69);
  color: white;
  right: 0;
}

.update-banner {
  position: sticky;
  top: 0;
  left: 0;
  padding: 5px;
  display: block;
  text-align: center;
  width: 100%;
  background: darkblue;
  color: white;
  right: 0;
}
</style>
