<template>
  <div class="container-fluid">
    <h1 class="title">Request Details</h1>

    <div class="info-table-wrapper">
      <table class="info-table">
        <thead>
        <tr>
          <th colspan="2" class="table-header">Store Information</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Store Name:</th>
          <td>{{ storeInfo.name }}</td>
        </tr>
        <tr>
          <th>Contact:</th>
          <td>{{ storeInfo.telephoneNumber }}</td>
        </tr>
        <tr>
          <th>Address:</th>
          <td>{{ storeInfo.physicalAddress }}</td>
        </tr>
        <tr>
          <th>Area:</th>
          <td>{{ storeInfo.storeArea }}</td>
        </tr>
        <tr>
          <th>TPIN:</th>
          <td>{{ storeInfo.tpinNumber }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <hr class="divider"/>

    <div class="info-table-wrapper">
      <table class="info-table">
        <thead>
        <tr>
          <th colspan="2" class="table-header">Business Representative Information</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Name:</th>
          <td>{{ businessInfo.name }}</td>
        </tr>
        <tr>
          <th>Phone Number:</th>
          <td>{{ businessInfo.telephoneNumber }}</td>
        </tr>
        <tr>
          <th>Email:</th>
          <td>
            <a :href="`mailto:${businessInfo.email}`">{{ businessInfo.email }}</a>
          </td>
        </tr>
        <tr>
          <th>National Registration Card (NRC):</th>
          <td>{{ businessInfo.nrcOrPassportNo }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <hr class="divider"/>

    <div class="info-table-wrapper">
      <table class="info-table">
        <thead>
        <tr>
          <th colspan="2" class="table-header">Documents</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(doc, index) in documents" :key="index">
          <td>
            <a @click="getDocumentUrl(doc)" class="document-link">{{ doc.name }}</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <hr class="divider"/>

    <div class="button-group">
      <button @click="acceptRequest" class="btn btn-accept">Accept</button>
      <button @click="showDeclinePopup" class="btn btn-decline">Decline</button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { Storage } from "@/services/Storage";

export default {
  name: "RequestDetails",
  data() {
    return {
      requestId: null,
      storeInfo: {},
      businessInfo: {},
      documents: [],
    };
  },
  created() {
    const requestId = this.$route.params.id;
    this.requestId = requestId;
    this.fetchRequestDetails(requestId);
  },
  methods: {
    async fetchRequestDetails(requestId) {
      this.$utils.showLoading();
      const apiUrl = synergy + `store-registration/view-pending-request/${requestId}`;
      return axios
          .get(apiUrl)
          .then(({ data }) => {
            this.storeInfo = {
              name: data.companyInfo.name,
              telephoneNumber: data.companyInfo.telephoneNumber,
              physicalAddress: data.companyInfo.physicalAddress,
              storeArea: data.companyInfo.storeArea,
              tpinNumber: data.companyInfo.tpinNumber,
            };
            this.businessInfo = {
              name: data.businessInfo.name,
              telephoneNumber: data.businessInfo.telephoneNumber,
              email: data.businessInfo.email,
              nrcOrPassportNo: data.businessInfo.nrcOrPassportNo,
            };
            this.documents = [
              {
                id: "signature",
                name: "Signature",
                base64: data.businessInfo.signature,
                type: "image/png",
              },
              {
                id: "nrc",
                name: "NRC",
                base64: data.businessInfo.nrcOrPassport,
                type: data.businessInfo.nrcOrPassportDocumentType,
              },
              {
                id: "certificate",
                name: "Pacra Certificate",
                base64: data.companyInfo.pacraCertificate,
                type: data.companyInfo.pacraDocumentType,
              },
              {
                id: "tpin",
                name: "TPIN Certificate",
                base64: data.companyInfo.tpinCertificate,
                type: data.companyInfo.tpinDocumentType,
              },
            ];
          })
          .catch((err) => errorHandler.tomcatError(err))
          .finally(() => this.$utils.hideLoading());
    },
    stripMimeType(base64) {
      const pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
      base64 = base64.replace(pattern, "");
      const binary = atob(base64); // Decode the base64 string
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Uint8Array(array);
    },
    getDocumentUrl(doc) {
      let data = this.stripMimeType(doc.base64);
      const blob = new Blob([data], { type: doc.type });
      let url = URL.createObjectURL(blob);
      return window.open(url);
    },
    async acceptRequest() {
      Swal.showLoading();
      try {
        let user = await Storage.getCurrentUser();
        const payload = {
          processedBy: user.Username, // Capture the user who processed the request
          invoiceNumber: this.requestId,  // Send the requestId in the body
        };
        await axios.post(synergy + `store-registration/approve-request`, payload);
        Swal.fire("Success", "Request accepted successfully!", "success");
      } catch (error) {
        Swal.fire("Error", "Failed to accept the request.", "error");
        errorHandler.tomcatError(error);
      }
    },
    async showDeclinePopup() {
      const { value: formValues } = await Swal.fire({
        title: "Decline Request",
        html: `
          <textarea id="decline-reason" class="swal2-textarea" placeholder="Enter the reason for declining..." style="margin-top: 10px;"></textarea>
        `,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "Send",
        preConfirm: () => {
          const declineReason = document.getElementById("decline-reason").value;
          if (!declineReason) {
            Swal.showValidationMessage("Reason is required");
            return null;
          }
          return declineReason;
        },
      });

      if (formValues) {
        this.declineRequest(formValues);
      }
    },
    async declineRequest(declineReason) {
      Swal.showLoading();
      try {
        let user = await Storage.getCurrentUser();
        const payload = {
          reason: declineReason,
          declinedBy: user.Username, // Capture the user who declined the request
          requestId: this.requestId, // Send the requestId in the body
        };
        await axios.post(synergy + `store-registration/decline-request`, payload);
        Swal.fire("Success", "Request declined successfully!", "success");
      } catch (error) {
        Swal.fire("Error", "Failed to decline the request.", "error");
        errorHandler.tomcatError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.info-table-wrapper {
  margin-top: 20px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
}

.info-table th,
.info-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-header {
  background-color: #f4f4f4;
  font-weight: bold;
}

.divider {
  margin: 20px 0;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.btn-accept,
.btn-decline {
  margin-right: 10px;
}

.btn-accept {
  background-color: blue;
  color: white;
}

.btn-decline {
  background-color: gray;
  color: white;
}

.document-link {
  text-decoration: none;
  color: #007bff;
}

.document-link:hover {
  text-decoration: underline;
}
</style>
