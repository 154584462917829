<template>
  <div>
    <div
        v-if="hover"
        class="nav-overlay"
        @click="close" @mouseenter="close"></div>
    <nav
        :class="{'expanded':hover}"
        class="main-menu"
        @click="toggle"
        @mouseenter="open"
        @mouseleave="close;"
    >
      <ul>
        <li>
          <router-link :to="{ name : 'user-dashboard' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-home"/>
              <span class="nav-text">Dashboard</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name : 'create-ticket' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-clipboard-check"/>
              <span class="nav-text">Add ticket</span>
            </div>
          </router-link>
        </li>
        <li v-if="isSynergyLive">
          <router-link :to="{ name : 'PendingEvents' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-clock"/>
              <span class="nav-text">KYC Validation</span>
            </div>
          </router-link>
        </li>
        <li>

        <li v-if="isSynergyLive">
          <router-link :to="{ name : 'PendingStock' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-arrow-trend-up"/>
              <span class="nav-text">Pending Stock</span>
            </div>
          </router-link>
        </li>
        <li v-if="isSynergyLive">
          <router-link :to="{ name : 'JobCardManagement' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-list-check"/>
              <span class="nav-text">Job Card Management</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name : 'search-ticket' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-search"/>
              <span class="nav-text">Search Tickets</span>
            </div>
          </router-link>
        </li>
        <li>
          <!--          new route to take you to the customers page.-->
          <router-link :to="{ name : 'customer-list' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-users"/>
              <span class="nav-text">Customers</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name : 'users' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-address-card"/>
              <span class="nav-text">Staff</span>
            </div>
          </router-link>
        </li>
        <!--testing-->
        <li >
          <router-link :to="{ name : 'calendar' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-calendar"/>
              <span class="nav-text">Calendar</span>
            </div>
          </router-link>
        </li>
<!--        <li>-->
<!--          <router-link :to="{ name : 'RequestFunds' }" @click.native="back">-->
<!--            <div class="nav-link">-->
<!--              <font-awesome-icon class="icon-style" icon="money-bill"/>-->
<!--              <span class="nav-text">Request Expense</span>-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </li>-->
        <li v-if="isSynergyLive">
          <router-link :to="{ name : 'Report' }" @click.native="back">
            <div class="nav-link">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-calendar"/>
              <span class="nav-text">Report</span>
            </div>
          </router-link>
        </li>

      </ul>
      <ul class="logout">
        <li>
          <a @click="logOut">
            <div class="nav-link">
              <font-awesome-icon class="material-icons icon-style" icon="fa-solid fa-arrow-right-from-bracket"/>
              <span class="nav-text">Logout</span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>

</template>

<script>
export default {
  name: "NavigationDrawer",
  data() {
    return {
      hover: false,
      timer: undefined
    }
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser
    },
    username() {
      return this.user.firstName + ` ` + this.user.lastName;
    },
    userType() {
      return this.user.Type;
    },
    isSynergyLive() {
      return this.$store.getters.getSynergyAvailability;
    },
  },
  methods: {
    toggle() {
      this.hover = !this.hover
    },
    open() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => this.hover = true, 500)
    },
    close() {
      clearTimeout(this.timer)
      this.hover = false
    },
    back() {
      this.close();
    },
    logOut() {
      this.back();
      return Swal.fire({
        title: 'Are you sure?',
        text: "You are about to be logged out!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log out!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({name: "login"})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.icon-style {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 85px;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  ul, li {
    outline: 0;
    margin: 0;
    padding: 0;
  }
}

.nav-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.main-menu {
  background: hsl(0, 0%, 13%);
  border-right: 1px solid hsl(0, 0%, 90%);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 3.8rem;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    margin: 0 0 0.438rem;
  }

  li {
    position: relative;
    display: block;
    width: 15.625rem;

    &:hover, &.active {
      background-color: rgb(11, 102, 255);

      a > .nav-link {
        color: hsl(0, 0%, 100%);
      }
    }

    a {
      height: 50px;
      position: relative;

      .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        color: hsl(0, 0%, 60%);
        font-size: 20px;
        height: 60px;
      }

      text-decoration: none;
      -webkit-transform: translateZ(0) scale(1, 1);
      -webkit-transition: all 0.1s linear;
      transition: all 0.1s linear;

      &.router-link-exact-active > .nav-link {
        background-color: rgb(11, 102, 255);
        color: white;
      }
    }
  }

  &.expanded {
    width: 15.625rem;
    overflow: visible;
  }

  .nav-text {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    font-size: 15px;
    width: 100%;
  }
}

a:hover,
a:focus {
  text-decoration: none;
}


</style>
