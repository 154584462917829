<template>
  <div>
<!--    <loading :active.sync="loading" :can-cancel="true" :is-full-page="false"></loading>-->

    <div class="d-flex gap-2 align-items-center justify-content-between w-100">
      <button class="back-button py-1 px-1">
        <font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />
        Back
      </button>

    </div>
    <div class="con">
      <!-- Stock Section -->
      <div class="container">
        <div class="row justify-content-center align-items-center text-center">
          <h3 class="fw-semibold">Stock</h3>
          <hr />
        </div>

        <div class="stepper">
          <div v-for="(step, index) in stockSteps" :key="index" :class="['step', step.completed ? 'active' : '']">
            <div class="step-circle">
              <font-awesome-icon :icon="step.icon" />
            </div>
            <div class="step-label">{{ step.label }}</div>
          </div>
        </div>
      </div>

      <!-- KYC Section -->
      <div class="container">
        <div class="row justify-content-center align-items-center text-center">
          <h3 class="fw-semibold">KYC</h3>
          <hr />
        </div>

        <div class="stepper">
          <div v-for="(step, index) in kycSteps" :key="index" :class="['step', step.is_current ? 'active' : '']">
            <div class="step-circle">
              <font-awesome-icon :icon="step.icon" />
            </div>
            <div class="step-label">{{ step.label }}</div>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="con">-->
<!--      <div class="container">-->
<!--        <div class="row justify-content-center align-items-center text-center">-->
<!--          <h3 class="fw-semibold">Stock</h3>-->
<!--           <hr />-->
<!--        </div>-->

<!--        <div class="stepper">-->
<!--          <div class="step active">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-box-open" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">NEW</div>-->
<!--          </div>-->
<!--          <div class="step active">-->
<!--            <div class="step-circle">-->
<!--              <font-awesome-icon icon="fa-solid fa-truck" />-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-boxes-stacked" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">PICKED</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-taxi" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">YANGO</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-bus" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">COURIER</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-building" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">OFFICE</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"> <font-awesome-icon icon="fa-solid fa-helmet-safety" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">TO SITE</div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

<!--      <div class="container">-->
<!--        <div class="row justify-content-center align-items-center text-center">-->
<!--          <h3 class="fw-semibold">KYC</h3>-->
<!--           <hr />-->
<!--        </div>-->

<!--        <div class="stepper">-->
<!--          <div class="step active">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-hourglass" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">Pending</div>-->
<!--          </div>-->

<!--          <div class="step active">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-circle-check" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">Kyc confirmed</div>-->
<!--          </div>-->

<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-boxes-stacked" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">Stock ready</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-headset" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">Bookable</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-circle-check" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">confirmed</div>-->
<!--          </div>-->
<!--          <div class="step">-->
<!--            <div class="step-circle">-->
<!--              <span class="material-symbols-outlined"><font-awesome-icon icon="fa-solid fa-trash" /></span>-->
<!--            </div>-->
<!--            <div class="step-label">Delete</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div>
      <div class="row mt-3">
        <div class="d-flex gap-2 align-items-center justify-content-end w-100">
          <!-- Button group -->
        </div>
      </div>
      <div class="row gap-2">
        <ReportHeader class="col-md-4 d-flex flex-column" />
        <div class="col-md-8">
          <div class="row justify-content-center align-items-center text-center">
            <h3 class="fw-semibold">See all the reports here</h3>
          </div>

          <hr />
          <nav>
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <button id="stock-logs-tab" aria-controls="stock-logs" aria-selected="true" class="nav-link active"
                      data-bs-target="#stock-logs" data-bs-toggle="tab" role="tab" type="button">
                <font-awesome-icon icon="fa-solid fa-list-check" />
                Stock Logs
              </button>
              <button id="expenses-tab" aria-controls="expenses" aria-selected="false" class="nav-link"
                      data-bs-target="#expenses" data-bs-toggle="tab" role="tab" type="button">
                <font-awesome-icon icon="fa-solid fa-money-bill" />
                Expenses
              </button>
              <button id="notes-tab" aria-controls="notes" aria-selected="false" class="nav-link"
                      data-bs-target="#notes" data-bs-toggle="tab" role="tab" type="button">
                <font-awesome-icon icon="fa-solid fa-comments" />
                Notes
              </button>
              <button id="invoices-tab" aria-controls="invoices" aria-selected="false" class="nav-link"
                     data-bs-target="#invoices" data-bs-toggle="tab" role="tab" type="button">
                <font-awesome-icon icon="fa-solid fa-receipt" />
                Invoices
              </button>
              <button id="products-services-tab" aria-controls="products-services" aria-selected="false"
                      class="nav-link"  data-bs-target="#products-services"
                      data-bs-toggle="tab" role="tab" type="button">
                Products & Services
              </button>
              <button id="milestones-tab" aria-controls="milestones" aria-selected="false" class="nav-link"
                      data-bs-target="#milestones" data-bs-toggle="tab" role="tab" type="button">
                Milestone
              </button>
              <button id="disputes-tab" aria-controls="disputes" aria-selected="false" class="nav-link"
                       data-bs-target="#disputes" data-bs-toggle="tab" role="tab" type="button">
                Disputes
              </button>
            </div>
          </nav>

          <div id="nav-tabContent" class="tab-content">

            <!-- notes -->
            <div id="notes" aria-labelledby="notes-tab" class="tab-pane fade" role="tabpanel">
              <h1>notes</h1>
              <div class="d-flex flex-column">
                <h6 class="text-secondary">Create note</h6>
                <button class="danger-btn" >
                  <font-awesome-icon icon="fa-solid fa-trash"/>
                </button>
                <br>
                <label >
                  <input  type="checkbox">
                  Internal note
                </label>

                <div  class="row  p-0 mt-2 mb-2">
                  <label for="Username">User Replying To</label>
                  <v-select id="Username">
                    <template #search="{attributes, events}">
                      <input
                          class="vs__search"
                          v-bind="attributes"
                          v-on="events"
                      />
                    </template>
                  </v-select>
                </div>



                <button class="primary-btn mt-3" >
                  <span  aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
                  <font-awesome-icon class="mr-2" icon="fa-solid fa-pencil"/>

                </button>
              </div>
            </div>

            <!-- logs -->
            <div id="stock-logs" aria-labelledby="stock-logs-tab" class="tab-pane fade show active" role="tabpanel">
              <div class="cd__main">
                <div class="container py-5">
                  <div class="row">
                    <div class="col-md-12 col-lg-12">
                      <div id="tracking-pre"></div>
                      <div id="tracking">
                        <div class="tracking-list">
                          <div v-for="item in trackingItems" class="tracking-item">
                            <div :class="['tracking-icon', item.status]">
                              <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                              </svg>
                            </div>
                            <div class="tracking-content">
                              <p>{{ item.currentUser }}</p>
                              <span class="badge bg-info text-black">{{ item.type }}</span>
                              <p><span>{{ item.date }}</span></p>
                              <p><strong>Origin:</strong> {{ item.origin }}</p>
                              <p><strong>Destination:</strong> {{ item.status }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Expenses Tab -->
            <div id="expenses" aria-labelledby="expenses-tab" class="tab-pane fade" role="tabpanel">
              <!-- Content for Expenses -->
<!--              <div class="h-100 page">-->

<!--                <div class="search-box shadow-sm d-flex py-1 px-3">-->
<!--                  <form >-->
<!--                    <div class="row">-->
<!--                      <div class="col-md-4">-->
<!--                        <div class="form-floating ">-->
<!--                          <input type="text" class="form-control"-->
<!--                                 placeholder="Invoice No.">-->
<!--                          <label >Invoice No.</label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-md-4">-->
<!--                        <div class="form-floating">-->
<!--                          <select class="form-select" id="pay-type">-->
<!--                            <option value="">All</option>-->
<!--                            <option >{{  }}</option>-->
<!--                          </select>-->
<!--                          <label for="pay-type">Payment Type</label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-md-4">-->
<!--                        <div class="form-floating">-->
<!--                          <input type="number" placeholder="SaleTotal"-->
<!--                                 id="sale-total" class="form-control">-->
<!--                          <label for="sale-total">Sale Total</label>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <button class="lyt-soft-btn" type="submit">Search</button>-->
<!--                    </div>-->
<!--                  </form>-->
<!--                </div>-->
<!--                <DynamicScroller-->
<!--                    :items="invoices"-->
<!--                    key-field="InvNum"-->
<!--                    :min-item-size="141"-->
<!--                    class="list-body h-100 w-100">-->
<!--                  <template v-slot="{ item, index, active }">-->
<!--                    <DynamicScrollerItem-->
<!--                        :item="item"-->
<!--                        :active="active"-->
<!--                        :data-index="index">-->
<!--                      <InvoiceRecord @click="openInvoice(item.InvNum)" :sale="item"/>-->
<!--                    </DynamicScrollerItem>-->
<!--                  </template>-->
<!--                </DynamicScroller>-->
<!--              </div>-->

              <div class="stock-card" >
                <div class="stock-card__content">
                  <div class="stock-card_img">
                    <font-awesome-icon class="icon" icon="fa-solid fa-money-bills"/>
                  </div>
                  <div class="stock-card_info">
                    <h6>Invoice No. : #{{ }} </h6>
                    <h6 class="text-muted stock-card_company">{{  }}</h6>
                  </div>
                  <h6>{{ }} Item(s)</h6>
                </div>
                <div class="stock-card__footer">
                  <div class="stock-card_stock-type">
                    <p class="mb-0 fw-bold text-secondary">Total : {{  }}</p>
                  </div>
                  <div>
                    <p class="mb-0 text-secondary">{{ }} </p>
                  </div>
                </div>
              </div>

            </div>

            <!-- Invoices Tab -->
            <div id="invoices" aria-labelledby="invoices-tab" class="tab-pane fade" role="tabpanel">
              <!-- Content for Invoices -->
<h1>invoice</h1>
              <div class="stock-card" v-for="item in invoice">
                <div class="stock-card__content">
                  <div class="stock-card_img">
                    <font-awesome-icon class="icon" icon="fa-solid fa-money-bills"/>
                  </div>
                  <div class="stock-card_info">
                    <h6>Invoice No. : #{{item.invoiceNumber }} </h6>
                    <h6 class="text-muted stock-card_company">{{  }}</h6>
                  </div>
                  <h6>{{ }} Item(s)</h6>
                </div>
                <div class="stock-card__footer">
                  <div class="stock-card_stock-type">
                    <p class="mb-0 fw-bold text-secondary">status : {{ item.status  }}</p>
                  </div>
                  <div>
                    <p class="mb-0 text-secondary">{{ }} </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Products & Services Tab -->
            <div id="products-services" aria-labelledby="products-services-tab" class="tab-pane fade" role="tabpanel">
              <!-- Content for Products & Services -->
              <h1>service</h1>
            </div>

            <!-- view milestone -->
            <div id="milestones" aria-labelledby="milestones-tab" class="tab-pane fade" role="tabpanel">
              <h1>mile</h1>
              <div class="milestone-list">
                <div v-for="milestone in milestones" :key="milestone.id" class="milestone-item mb-2 card d-flex flex-column p-3">
                  <div class="d-flex">
                    <div>
                      <button v-if="loading" class="ticket-task-action">
                        <span class="spinner-border spinner-border-sm"></span>
                      </button>
                      <button v-else-if="milestone.status==='PENDING'" class="ticket-task-action" @click="markMilestoneAsResolved(milestone.id)">
                        <font-awesome-icon  icon="fa-regular fa-circle-check"/>
                      </button>
                      <button v-else class="ticket-task-action" >
                        <font-awesome-icon class="completed" icon="fa-solid fa-circle-check"/>
                      </button>
                    </div>
                    <div>
                      <div class="milestone-title">
                        <p><strong>SequenceNumber: </strong>{{ milestone.sequenceNumberreportedBy }}</p>
                        <span class="milestone-status" :class="milestone.status">{{ milestone.status }}</span>
                      </div>
                      <div class="milestone-details mt-2">
                        <p><strong>Name:</strong>{{ milestone.name }}</p>
                        <p><strong>Type:</strong> {{ milestone.type}}</p>
                        <p><strong>StartDateTime:</strong> {{ milestone.startDateTime }}</p>
                        <p><strong>EndDateTime:</strong> {{ milestone.endDateTime}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- view dispute -->
            <div id="disputes" aria-labelledby="disputes-tab" class="tab-pane fade" role="tabpanel">
              <h1>disputes</h1>
              <div class="dispute-list">
                <div class=" mb-2 pr-0">
                  <button class="col-md-4  primary-btn p-2" >
                    <font-awesome-icon icon="fa-solid fa-plus" class="mr-2"/>
                    Add Dispute
                  </button>
                </div>
                <div v-for="dispute in disputes" :key="dispute.id" class="dispute-item mb-2 card d-flex flex-column p-3">
                  <div class="d-flex">
                    <div>
                      <button v-if="loading" class="ticket-task-action">
                        <span class="spinner-border spinner-border-sm"></span>
                      </button>
                      <button v-else class="ticket-task-action" @click=" markDisputeAsResolved(dispute.id)">
                        <font-awesome-icon v-if="dispute.completed" class="completed" icon="fa-solid fa-circle-check"/>
                        <font-awesome-icon v-else icon="fa-regular fa-circle-check"/>
                      </button>
                    </div>
                    <div>
                      <div class="dispute-title">
                        <p><strong>Reported By: </strong>{{ dispute.reportedBy }}</p>
                        <span class="dispute-status" :class="dispute.status">{{ dispute.status }}</span>
                        <p><strong>Details:</strong> {{ dispute.details }}</p>
                        <p><strong>Date:</strong> {{ dispute.date }}</p>
                      </div>
                      <div class="dispute-details mt-2">

                        <p><strong>Processed By:</strong> {{ dispute.processedBy }}</p>
                        <p><strong>Processed Date:</strong> {{ dispute.processedDate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from "@/components/tickets/view/ReportHeader";
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: 'Reports',
  data() {
    return {
      stockSteps: [], // for stock data
      kycSteps: [], // for KYC data
      trackingItems: [],
      invoice:[],
      expenes:[],
      disputes:[],
      loading: false,
      milestones:[],
    }
  },
  beforeMount() {
    this.fetchStockData();
    this.fetchlogData();
    this.getMilestones();
    this. getDisputes();
    // this.fetchexpenesData();
    // this.fetchnotesData();
    this.invoiceData();
    // this.fetchData();
    this.fetchKYCData();
  },

  components:{
    ReportHeader
  },

  methods: {
    async getDisputes() {
      try {
        this.loading = true;
        this.disputes = [];

        const response = await axios.get(synergy6+'stats/disputes?jobcardHeaderId=12&limit=5');

        this.disputes = response.data; // Update with disputes data

      } catch (error) {
        console.error('Failed to fetch disputes:', error);
      } finally {
        this.loading = false;
      }
    },

    async getMilestones() {
      try {

        this.milestones = [];
        const response = await axios.get(synergy6+`stats/milestones?jobcardHeaderId=4&limit=5`);

        // Assuming response.data is an array of milestones
        this.milestones = response.data;

      } catch (error) {
        return errorHandler.tomcatError(error)
      }
    },

    async fetchStockData() {
      try {
        // this.$utils.showLoading();
        const response = await axios.get(synergy+'delivery/getDispatchStates/2');

        this.stockSteps = this.formatSteps(response.data);

        // Ensure you are accessing the array items correctly
        // this.stockSteps.forEach((step, index) => {
        //   console.log(`Step ${index}:`, step);
        //   console.log(`Completed: ${step.completed}`);
        //   console.log(`Status: ${step.label}`);
        // });

      } catch (error) {
        return errorHandler.tomcatError(error);
      }
      // finally {
      //   this.$utils.hideLoading();
      // }
    },

    async fetchlogData() {
      try {

        const response = await axios.get(synergy+'delivery/getDispatchLogs/2');
        this.trackingItems = response.data;

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while fetching stock data.',
        });
        console.error('Error fetching stock data:', error);
      }
    },

    // async fetchexpenesData(jobCardHeader) {
    //   try {
    //     this.loading = true;
    //     const response = await axios.get(synergy+'jobcards/jobcard-expenses/'+ jobCardHeader);
    //     console.log(response.data);
    //     this.expenes = response.data;
    //     console.log(this.expenes);
    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Something went wrong while fetching stock data.',
    //     });
    //     console.error('Error fetching stock data:', error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    //
    // async fetchnotesData() {
    //   try {
    //     this.loading = true;
    //     const response = await axios.get('http://192.168.30.10:8080/SynergyApi/api/v1/jobcards/jobcard-notes/header');
    //     console.log(response.data);
    //     this.notes = response.data;
    //     console.log(this.notes);
    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Something went wrong while fetching stock data.',
    //     });
    //     console.error('Error fetching stock data:', error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    //
    async invoiceData() {
      console.log("Fetching stock data");

      try {
        const response = await axios.get(synergy+'jobcards/jobCardInvoices/4');
        console.log(response.data);
        this.invoice = this.response.data;
        console.log("invoice");
        console.log(this.invoice);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while fetching stock data.',
        });
        console.error('Error fetching stock data:', error);
      }
    },
    //
    // async fetchData() {
    //   console.log("Fetching stock data");
    //   this.loading = true;
    //   try {
    //     const response = await axios.get(' http://192.168.30.15:8080/SynergyApi/swagger-ui/index.html#/');
    //     console.log(response.data);
    //     this. invoice = this. response.data;
    //     console.log(this.invoice);
    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Something went wrong while fetching stock data.',
    //     });
    //     console.error('Error fetching stock data:', error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    //
    // formatTrackingItems(data) {
    //   // Format the data as needed
    //   return data.map(item => ({
    //     where_between: item.where_between, // Ensure this matches the API response
    //     currentUser: item.currentUser, // Adjust as needed
    //     date: item.date, // Ensure this is in the correct format
    //     endpoint: item.endpoint,
    //   }));
    // },
    //
    // formatDate(date) {
    //   // Format the date object into a readable string
    //   const { year, month, day } = date.date;
    //   return `${year}-${month}-${day}`;
    // },
    //
    async fetchKYCData() {
      try {
        const response = await axios.get(synergy6+'stats/kyc?jobHeaderId=12'); // Replace with your KYC API URL
        console.log("kyc");
        this.kycSteps =  this.formatSteps(response.data);
        console.log(this.kycSteps);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while fetching KYC data.',
        });
        console.error('Error fetching KYC data:', error);
      }
    },

    formatSteps(data) {
      // Step 1: Format the initial steps
      let steps = data.map(item => ({
        label: item.status,
        completed: item.completed,
        is_current: item.is_current,
        icon: this.getIconForStatus(item.status)
      }));

      // // Step 2: Check if both STOCK_READY and KYC_READY exist
      // const stockReady = steps.find(step => step.label === 'STOCK_READY');
      // const kycReady = steps.find(step => step.label === 'KYC_READY');
      //
      // if (stockReady || kycReady) {
      //   // Step 3: Remove STOCK_READY and KYC_READY
      //   steps = steps.filter(step => step.label !== 'STOCK_READY' && step.label !== 'KYC_READY');
      //
      //   // Step 4: Add IN_PROGRESS instead
      //   steps.push({
      //     label: 'IN_PROGRESS',
      //     is_current: true,
      //     icon: this.getIconForStatus('PENDING')
      //   });
      // }

      // Step 5: Return the final steps array
      return steps;
    },


    getIconForStatus(status) {
      const icons = {
        'NEW': 'fa-box-open',
        'PICKED': 'fa-boxes-stacked',
        'YANGO': 'fa-taxi',
        'COURIER': 'fa-bus',
        'OFFICE': 'fa-building',
        'TO SITE': 'fa-helmet-safety',
        'PENDING': 'fa-hourglass',
        'KYC_CONFIRM': 'fa-circle-check',
        'STOCK_READY': 'fa-boxes-stacked',
        'BOOKABLE': 'fa-headset',
        'CONFIRMED': 'fa-circle-check',
        'COMPLETED': 'fa-circle-check'
      };
      return icons[status] || 'fa-question'; // default icon
    },

  }
}
</script>

<style lang="scss" scoped>
.container{
  margin-bottom: 50px;
}
.tab-content {
  padding: 10px 5px;
}

.no-attachments {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f8f9fa;
}

.no-attachments-content {
  text-align: center;
}

.no-attachments-img {
  max-width: 150px;
  margin-bottom: 20px;
}

.file-icon {
  font-size: 1.5rem;
}

.text-sm {
  font-size: 12px !important;
}

.max-width-20{
  max-width: 30%
}
//milestone
.no-milestones {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f8f9fa;
}

.no-milestones-content {
  text-align: center;
}

.milestone-card {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}


.blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  50% { border-color: #fff; }
}
.card {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
//.card:hover{
//  transition: 2px;
//}
.milestone-item, .dispute-item {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.milestone-status.completed {
  color: green;
}
.milestone-status.pending {
  color: orange;
}
.milestone-status.in-progress {
  color: blue;
}
.ticket-task-action {
  width: 50px;
  max-width: 50px;
  height: 48px;
  font-size: 25px;
  background: none;
  color: rgb(53, 68, 88);
}

.ticket-task-action .completed {
  color: #67C57B;
}


/* Custom SweetAlert2 Styling */
.swal2-container {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Center vertically */
}

.swal2-popup {
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center text and elements inside */
  width: 90%; /* Adjust width if needed */
  max-width: 500px; /* Set a max-width for large screens */
}

.swal2-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.swal2-html-container {
  font-size: 1rem;
}

.swal2-input, .swal2-textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.swal2-input {
  height: 2rem;
}

.swal2-textarea {
  resize: vertical;
}

.swal2-confirm, .swal2-cancel {
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.swal2-confirm {
  background-color: #007bff;
  color: #fff;
}

.swal2-cancel {
  background-color: #6c757d;
  color: #fff;
}

//nav bar
.container {
  width: 80%;
  max-width: 800px;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  z-index: 1;
  position: relative;
}

.step-label {
  margin-top: 8px;
  font-size: 14px;
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
}

.step.active .step-circle {
  background-color: #0B66FF;
}

.step.active:not(:last-child)::after {
  background-color: #0B66FF;
}

.step:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -50%;
  width: 100%;
  height: 2px;
  background-color: inherit;
  z-index: 0;
}


//view log

#tracking {
  background: #fff;
}
.tracking-detail {
  padding: 3rem 0;
}
#tracking {
  margin-bottom: 1rem;
}
[class*="tracking-status-"] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
[class*="tracking-status-"] {
  padding: 1.6rem 0;
}
.tracking-list {
  border: 1px solid #e5e5e5;
}
.tracking-item {
  border-left: 4px solid #0D6EFD;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}
.tracking-item:last-child {
  padding-bottom: 4rem;
}
.tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}
.tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
.tracking-item .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}
.tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
.tracking-item .tracking-icon {
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
}
.tracking-item-pending {
  border-left: 4px solid #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}
.tracking-item-pending:last-child {
  padding-bottom: 4rem;
}
.tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}
.tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
.tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}
.tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
.tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}
.tracking-item-pending .tracking-content {
  font-weight: 600;
  font-size: 17px;
}
.tracking-item .tracking-icon.status-current {
  width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem;
}
.tracking-item .tracking-icon.status-intransit {
  color: #0D6EFD;
  font-size: 0.6rem;
}
.tracking-item .tracking-icon.status-current {
  color: #0D6EFD;
  font-size: 0.6rem;
}
@media (min-width: 992px) {
  .tracking-item {
    margin-left: 10rem;
  }
  .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  .tracking-item .tracking-date span {
    display: block;
  }
  .tracking-item .tracking-content {
    padding: 0;
    background-color: transparent;
  }
  .tracking-item-pending {
    margin-left: 10rem;
  }
  .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  .tracking-item-pending .tracking-date span {
    display: block;
  }
  .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}
.tracking-item .tracking-content {
  font-weight: 600;
  font-size: 17px;
}
.blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  50% { border-color: #fff; }
}

//invoice

.icon {
 font-size: 25px;
 margin: 10px;
}

p {
  margin: 0;

}

.stock-card_company {
  font-size: 12px;
}

.stock-card {
  background: #fff;
  padding: 6px 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d2d2d2;

  &__content {
    display: flex;
    align-items: center;
    //margin-bottom: 5px;

    .stock-card_img > .icon-img {
      margin-right: 10px;
      font-size: 20px;

      img {
        height: 60px;
        width: 60px;
        opacity: 0.7;
        object-fit: contain;
        border-radius: 8px;
      }
    }

    .stock-card_info {
      flex: 1;

      .text-muted a {
        color: #777;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stock-card_stock-type {
      width: 60%;
    }

    .stock-card-price {
      font-weight: 500;
      font-size: 18px;
      color: #40434A;
    }
  }
}

.stock-label {
  display: inline-block;
  padding: 2px 12px;
  font-size: 10px;
  font-weight: 700;
  background: #f5f5f5;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin-right: 2px;
  color: #40434A;
}

h6, h4, p {
  margin: 0;
}
.page {
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-box {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}

.col-md-4 {
  margin-bottom: 10px;

}

</style>