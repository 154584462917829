<template>
  <div class="container-fluid">
    <h1 class="title">Pending Stock Deliveries</h1>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else class="table-wrapper">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th>Stock Number</th>
          <th>Customer Name</th>
          <th>Invoice No</th>
          <th>Job Status</th>
          <th>Stock Status</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ row.customerName }}</td>
          <td>{{ row.invoiceNumber }}</td>
          <td>{{ row.jobCardStatus }}</td>
          <td>{{ row.dispatchStatus }}</td>
          <td>{{ row.createdDate }}</td>
          <td>
            <button @click="showStockDetails(row)" class="btn view-button">
              <font-awesome-icon class="icon-style" icon="fa-solid fa-eye" />
              <span class="action-text">View Stock Details</span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
  name: 'PendingStockDeliveries',
  data() {
    return {
      rows: [],
      loading: true,
    };
  },
  created() {
    this.fetchPendingStockDeliveries();
  },
  methods: {
    async fetchPendingStockDeliveries() {
      try {
        const apiUrl = `${window.synergy3}api/v1/delivery/getDispatchPending`;
        const response = await axios.get(apiUrl);
        this.rows = response.data;
      } catch (error) {
        console.error('Error fetching pending stock deliveries:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Fetch Data',
          text: 'Could not retrieve pending stock deliveries.',
        });
      } finally {
        this.loading = false;
      }
    },
    async showStockDetails(stock) {
      return this.printQRCode(stock.jobCardHeaderID);
    },
    async checkStock(stockId) {
      try {
        const apiUrl = `${window.synergy4}api/v1/delivery/getItemDocuments`; // Ensure this endpoint is correct
        const response = await axios.get(apiUrl, {
          params: { stockId }, // Ensure correct parameter is used
        });
        const stockData = response.data;

        Swal.fire({
          title: 'Stock Information',
          html: `
            <p><strong>Stock ID:</strong> ${stockData.stockId}</p>
            <p><strong>Stock Name:</strong> ${stockData.stockName}</p>
            <p><strong>Available Quantity:</strong> ${stockData.availableQuantity}</p>
          `,
          icon: 'info',
        });
      } catch (error) {
        console.error('Error checking stock:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to Check Stock',
          text: 'Could not retrieve stock information.',
        });
      }
    },
    async printQRCode(jobCardHeader) {
      const apiUrl = synergy + `delivery/getDispatchInfo/` + jobCardHeader;
      return window.open(apiUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
}

.title {
  margin: 20px;
  text-align: center;
}

.loading {
  text-align: center;
  font-size: 18px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
}

.view-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.view-button .icon-style {
  margin-right: 5px;
}

.action-text {
  font-size: 12px;
}

@media (max-width: 576px) {
  .view-button {
    font-size: 12px;
    padding: 5px;
  }

  .action-text {
    display: block;
    text-align: center;
    margin-top: 4px;
  }
}
</style>
