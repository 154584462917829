<template>
  <div class="job-card-management-page">
    <h1 class="title">Job Card Management</h1>

    <!-- Filter Section with Larger and Responsive Inputs -->
    <div class="row filter-buttons-container">
      <!-- Status Dropdown -->
      <div class="col-12 col-md-6 mb-3">
        <label for="status-dropdown" class="form-label">Status:</label>
        <select
            id="status-dropdown"
            class="form-select form-select-lg"
            v-model="selectedStatus"
        >
          <option value="" disabled>Select Status</option>
          <option value="PENDING">Pending</option>
          <option value="KYC CONFIRM">KYC Confirm</option>
          <option value="STOCK READY">Stock Ready</option>
          <option value="BOOKABLE">Bookable</option>
          <option value="CONFIRMED">Confirmed</option>
          <option value="COMPLETED">Completed</option>
        </select>
      </div>

      <!-- Stock Type Dropdown -->
      <div class="col-12 col-md-6 mb-3">
        <label for="stock-type-dropdown" class="form-label">Stock Type:</label>
        <select
            id="stock-type-dropdown"
            class="form-select form-select-lg"
            v-model="selectedStockType"
        >
          <option value="" disabled>Select Stock Type</option>
          <option value="NEW">New</option>
          <option value="PICKED">Picked</option>
          <option value="TRANSIT">Transit</option>
          <option value="OFFICE">Office</option>
          <option value="TO SITE">To Site</option>
        </select>
      </div>

      <!-- Customer Name Input -->
      <div class="col-12 col-md-6 mb-3">
        <label for="customer-name" class="form-label">Customer Name:</label>
        <input
            type="text"
            id="customer-name"
            class="form-control form-control-lg"
            v-model="customerName"
            placeholder="Enter Customer Name"
        />
      </div>

      <!-- Invoice Number Input -->
      <div class="col-12 col-md-6 mb-3">
        <label for="invoice-number" class="form-label">Invoice Number:</label>
        <input
            type="text"
            id="invoice-number"
            class="form-control form-control-lg"
            v-model="invoiceNumber"
            placeholder="Enter Invoice Number"
        />
      </div>

      <!-- Time Frame Checkbox -->
      <div class="col-12 mb-3">
        <div class="form-check form-check-lg">
          <input
              class="form-check-input"
              type="checkbox"
              id="time-frame-checkbox"
              v-model="showTimeFrame"
          />
          <label class="form-check-label" for="time-frame-checkbox">
            Enable Time Frame
          </label>
        </div>
      </div>

      <!-- Time Frame Inputs -->
      <div class="row" v-if="showTimeFrame">
        <div class="col-12 col-md-6 mb-3">
          <label for="from-date" class="form-label">From:</label>
          <input
              type="date"
              id="from-date"
              class="form-control form-control-lg"
              v-model="fromDate"
          />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label for="to-date" class="form-label">To:</label>
          <input
              type="date"
              id="to-date"
              class="form-control form-control-lg"
              v-model="toDate"
          />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="col-12 mb-3">
        <button class="btn btn-primary btn-lg w-100 submit-btn" @click="submitData">Submit</button>
      </div>
    </div>

    <!-- Success Message -->
    <div
        v-if="showSuccessMessage"
        class="alert alert-success text-center mt-3"
    >
      Submitted successfully!
    </div>

    <!-- Dynamic Filter Content -->
    <div class="dynamic-filter-content" v-if="showFilterContent">
      <h3>{{ tableTitle }}</h3>

      <!-- Table for Data Display -->
      <vue-good-table
          :columns="columns"
          :rows="filteredData"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'action'">
            <a @click="" class="btn btn-outline-info">
              <font-awesome-icon icon="eye" />
            </a>
          </div>

          <div v-else-if="props.column.field === 'status'">
           <span class="badge bg-info">{{ props.row.status }}</span>
          </div>

          <div v-else-if="props.column.field === 'createdDate'">
           <span >{{ $utils.displayDate(props.row.createdDate) }}</span>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

export default {
  name: "JobCardManagement",
  data() {
    return {
      showFilterContent: false,
      selectedStatus: "PENDING",
      selectedStockType: "",
      customerName: "",
      invoiceNumber: "",
      fromDate: "",
      toDate: "",
      showTimeFrame: false,
      tableTitle: "Pending Job Cards",
      filteredData: [],
      showSuccessMessage: false,
      columns: [
        { label: "Job ID", field: "id" },
        { label: "Customer Name", field: "customerName" },
        { label: "Invoice Number", field: "invoiceNumber" },
        { label: "Contact Number", field: "contactNumber" },
        { label: "Created Date", field: "createdDate" },
        { label: "Status", field: "status" },
        { label: "Action", field: "action" , sortable : false },
      ],
    };
  },
  methods: {
    async submitData() {
      try {
        const response = await axios.get(
            window.synergy5 + "jobcards/pending-jobcards",
            {
              params: {
                status: this.selectedStatus,
                stockType: this.selectedStockType,
                customerName: this.customerName,
                invoiceNumber: this.invoiceNumber,
                fromDate: this.showTimeFrame ? this.fromDate : null,
                toDate: this.showTimeFrame ? this.toDate : null,
              },
            }
        );

        this.filteredData = response.data;
        console.log(this.filteredData);
        this.showFilterContent = true;
        this.showSuccessMessage = true;

        // Hide success message after 3 seconds
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 3000);
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
  },
  beforeMount() {
    this.submitData();
  },
};
</script>

<style scoped>
.job-card-management-page {
  max-width: 100%;
  margin: 0;
  padding: 20px;
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: bold;
  font-size: 1.8rem;
}

.filter-buttons-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  color: #2c3e50;
}

.form-control-lg,
.form-select-lg {
  border-radius: 6px;
  border: 1px solid #ced4da;
  padding: 12px;
}

.form-select-lg {
  height: auto;
}

.form-check-label {
  color: #34495e;
  font-size: 1rem;
}

.dynamic-filter-content h3 {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  color: #2c3e50;
  font-size: 1.5rem;
}

.submit-btn {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.alert-success {
  border-radius: 6px;
}

@media (max-width: 768px) {
  .filter-buttons-container {
    padding: 15px;
  }

  .submit-btn {
    font-size: 1rem;
  }
}
</style>
