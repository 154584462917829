import {DateTimeFormatter, Duration, LocalDateTime} from "@js-joda/core";
import {Locale} from "@js-joda/locale_en";

function formatDateNumber(number) {
    return number >= 10 ? number : `0` + number;
}

const defaultFormat = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")
    .withLocale(Locale.ENGLISH)
const thisYearFormat = DateTimeFormatter.ofPattern("E dd MMM, HH:ss")
    .withLocale(Locale.ENGLISH);
const otherYearFormat = DateTimeFormatter.ofPattern("dd/MM/yyyy, HH:ss")
    .withLocale(Locale.ENGLISH)
const todayFormat = DateTimeFormatter.ofPattern("HH:mm")
    .withLocale(Locale.ENGLISH)

function parseDate(dateObject) {
    let date = dateObject.date;
    let time = dateObject.time;
    return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)} ${formatDateNumber(time.hour)}:${formatDateNumber(time.minute)}:${formatDateNumber(time.minute)}`;
}

function handleDateExpiry(dateString) {
    dateString = dateString + 'T00:00:00';
    let date = LocalDateTime.parse(dateString);
    switch (date.dayOfWeek()._name) {
        case 'SATURDAY':
            date = date.minusDays(1)
            break;
        case 'SUNDAY':
            date = date.minusDays(2)
            break;
    }
    return date.format(defaultFormat).slice(0, -9);
}

export const dateTimeManager = {
    formatJavaDate(dateObject) {
        let date = dateObject.date;
        return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)}`;
    },
    formatJavaDateTime(dateObject) {
        if (!dateObject) {
            return `Invalid Date`;
        }
        let date = dateObject.date;
        let time = dateObject.time;
        return `${date.year}-${formatDateNumber(date.month)}-${formatDateNumber(date.day)}T${formatDateNumber(time.hour)}:${formatDateNumber(time.minute)}`;
    },

    addHoursToDate(dateObject,duration) {
        if (!dateObject) {
            return `Invalid Date`;
        }

        if (!duration) {
            duration = 2;
        }
        let parsedDate = parseDate(dateObject);
        return LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
            .plusHours(Number(duration)).format(defaultFormat);
    },



    dateForDisplay(dateObject) {
        let formattedDate = parseDate(dateObject)
        return new Date(formattedDate).toString().slice(3, 21);
    },
    shortenDisplay(dateObject) {
        let parsedDate = parseDate(dateObject);
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now()
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at `+formattedDate.format(todayFormat)
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formattedDate.format(thisYearFormat)
        } else {
            return formattedDate.format(otherYearFormat)
        }
    },
    shortenStandardDisplay(parsedDate) {
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now()
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at `+formattedDate.format(todayFormat)
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formattedDate.format(thisYearFormat)
        } else {
            return formattedDate.format(otherYearFormat)
        }
    },

    getStartOfThisMonth(){
        return LocalDateTime.now().withDayOfMonth(1).format(defaultFormat)
    },

    getNow() {
        return LocalDateTime.now().format(defaultFormat);
    },

    getTomorrow() {
        return LocalDateTime.now().plusDays(1).format(defaultFormat);
    },

    getNext7Days() {
        return LocalDateTime.now().plusDays(7).format(defaultFormat);
    },

    getYesterday() {
        return LocalDateTime.now().minusDays(1).format(defaultFormat);
    },

    getNextMonthlyExpiryDate() {
        let dateString = LocalDateTime.now().plusMonths(1).format(defaultFormat).slice(0, -11) + '07';
        return handleDateExpiry(dateString);
    },

    getNextYearlyExpiryDate() {
        let dateString = LocalDateTime.now().plusYears(1).format(defaultFormat).slice(0, -11) + '07';
        return handleDateExpiry(dateString);
    },

    getNextTwoDaysForForm() {
        return LocalDateTime.now().plusDays(2)
            .format(defaultFormat)
            .replace(' ', 'T')
            .slice(0, -3)
    },

    getNextWeekForForm() {
        return LocalDateTime.now().plusWeeks(1)
            .format(defaultFormat)
            .replace(' ', 'T')
            .slice(0, -3)
    },


    timeAgo(dateObject, showAgoIfLonger) {
        let parsedDate = parseDate(dateObject);
        let date = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now();
        let duration = Duration.between(date, now);
        console.log(duration)
        console.log(duration.toJSON())
        console.log(duration.toString())
        if (duration.toDays() > 1) {
            console.log("Duration greater than 1: " + duration.toDays())
        } else if (duration.toDays() === 1) {
            console.log("Duration greater equal to 1: " + duration.toDays())
        } else {
            console.log("Duration greater equal to 1: " + duration.toDays())
        }
    },

    hasDatePassed(date){
      let now = new Date();
      let other = new Date(date);
      return now > other;
    },


}